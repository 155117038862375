<template>
  <ul>
    <li class="has-sub" v-for="category in categories" :key="category._id">
      <span>{{ category }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'BaseCategory',
  props: ['categories'],
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/layout.scss';
</style>
