<template>
  <div class="message" v-if="!posts">
    <BaseDialog :isLoading="loading" :isError="error"></BaseDialog>
  </div>

  
  <transition name="fade">
    <BaseLoader v-if="overlay" :value="overlay"></BaseLoader>
  </transition>

  
  <full-page :options="options" id="fullpage" ref="fullpage" v-if="isDesktop">
    <section
      class="section"
      v-for="(project, index) in posts.projects"
      :key="index"
    >
      <portfolio-slide :project="project" :index="index"></portfolio-slide>
    </section>
  </full-page>

  
  <full-page :options="options" id="fullpage" ref="fullpage" v-if="isDevice">
    <section class="section">
      <div class="slide" v-for="(project, index) in posts.projects" :key="index">
        <portfolio-slide :project="project" :index="index"></portfolio-slide>
      </div>
    </section>
  </full-page>

  
  <about-us v-if="!overlay" :navbar="posts.navbar"></about-us>
</template>

<script>
import BaseDialog from './components/UI/BaseDialog.vue';
import BaseLoader from './components/UI/BaseLoader.vue';
import PortfolioSlide from './views/PortfolioSlide.vue';
import AboutUs from './views/AboutUs.vue';

import fullPageMixin from './components/mixins/fullPage.js';
import sanityMixin from './components/mixins/sanity.js';

export default {
  name: 'App',
  mixins: [fullPageMixin, sanityMixin],
  components: {
    BaseDialog,
    PortfolioSlide,
    AboutUs,
    BaseLoader
  },
  data() {
    return {
      overlay: true,
    };
  },
  computed: {
    isDevice() {
      return !this.overlay && this.window.width <= 960;
    },
    isDesktop() {
      return !this.overlay && this.window.width > 960;
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    setTimeout(() => {
      this.overlay = false;
    }, 4000);
  },
};


</script>

<style lang="scss">
/* write SCSS here */

.fade-enter-from { opacity: 0 }

.fade-enter-active,
.fade-leave-active {
  transition: opacity 500ms ease
}
.fade-leave-to   { opacity: 0 }

#app {
  font-family: $font-regular;
}

</style>
