<template>
  <div class="accordion-wrapper">
    <div class="accordion">
      <div class="accordion__content">
        <div class="accordion__content__inner accordion-body">
          <h2 class="accordion-header text-uppercase">{{ navbar.title }}</h2>
          <SanityBlocks  :blocks="navbar.body" />
        </div>
      </div>
      <div class="accordion__footer" @click="accordionToggle">
        <div class="panel">
          <div class="circle">
            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.00355 15.8729V14.5519H2.00089C1.8008 14.5519 1.63406 14.4941 1.51734 14.3703C1.39228 14.2547 1.33393 14.0896 1.33393 13.8915V2.6636H0V15.8729H8.00355Z" />
              <path d="M9.35544 10.6109V11.2741C9.35544 11.473 9.28933 11.6388 9.17364 11.7549C9.04969 11.8792 8.89261 11.9373 8.69429 11.9373H4.72753C4.52919 11.9373 4.36391 11.8792 4.24821 11.7549C4.12424 11.6388 4.06639 11.473 4.06639 11.2741V1.98954C4.06639 1.79059 4.12424 1.63308 4.24821 1.50874C4.36391 1.39268 4.52919 1.32636 4.72753 1.32636H8.69429C8.89261 1.32636 9.04969 1.39268 9.17364 1.50874C9.28933 1.63308 9.35544 1.79059 9.35544 1.98954V2.65272H10.0166C10.2149 2.65272 10.3719 2.71904 10.4959 2.8351C10.6116 2.95944 10.6777 3.11695 10.6777 3.3159V9.94773C10.6777 10.1467 10.6116 10.3125 10.4959 10.4285C10.3719 10.5529 10.2149 10.6109 10.0166 10.6109H9.35544ZM10.6777 1.98954V1.32636H10.0166C9.81819 1.32636 9.65292 1.26833 9.53724 1.14399C9.41328 1.02793 9.35544 0.862138 9.35544 0.663181V0H2.74414V13.2636H9.35544V12.6004C9.35544 12.4015 9.41328 12.244 9.53724 12.1196C9.65292 12.0036 9.81819 11.9373 10.0166 11.9373H10.6777V11.2741C10.6777 11.0751 10.7355 10.9176 10.8595 10.7933C10.9752 10.6772 11.1405 10.6109 11.3388 10.6109H12V2.65272H11.3388C11.1405 2.65272 10.9752 2.59469 10.8595 2.47035C10.7355 2.35429 10.6777 2.18849 10.6777 1.98954Z" />
            </svg>
          </div>
          <ul class="main-menu">
            <li class="main-link nav-left">
              <h3 class="has-submenu text-uppercase">Laurton Digital</h3>
            </li>
            <li class="main-link nav-right">
              <!-- Toggle button -->
              <!-- Use any element to open/show the overlay navigation menu -->
              <button class="has-submenu text-uppercase" aria-label="Toggle panel">
                {{ toggleSeen }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SanityBlocks } from 'sanity-blocks-vue-component';
export default {
  name: 'AboutUs',
  props: ['navbar'],
  components: { SanityBlocks },
  data() {
    return {
      seen: false,
    };
  },
  computed: {
    toggleSeen() {
      return !this.seen ? this.navbar.title : 'Close';
    },
  },
  methods: {
    accordionToggle() {
      const item = document.querySelector('.accordion');
      //const contentHeight = item.querySelector('.accordion__content__inner').offsetHeight;
      const openAccordion = document.querySelector('.accordion.open');
      const desktopBuffer = 92;
      const mobileBuffer = 80;
      let buffer;

      if(window.innerWidth > 768) {
         buffer = desktopBuffer;
      } else {
         buffer = mobileBuffer;
      }

      // Check to one is open and close if so
      if (openAccordion) {
        openAccordion.classList.remove('open');
        openAccordion.querySelector('.accordion__content').style.maxHeight = 0;
      }

      // If open one is not clicked one, open it
      if (openAccordion !== item) {
        //item.querySelector('.accordion__content').style.maxHeight = contentHeight + 'px';
        let viewPortHeight = Math.max(
          document.documentElement.clientHeight,
          window.innerHeight || 0
        );
        item.querySelector('.accordion__content').style.maxHeight = viewPortHeight - buffer + 'px';
        item.classList.add('open');
      } //92px

      this.seen = !this.seen;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/assets/scss/navbar.scss';
.main-link button {
  border: none;
  background: none;
  outline: none;
  -webkit-appearance: none;

  &:hover {
    cursor: pointer;
  }
}
</style>
