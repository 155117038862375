export default {
  data() {
    return {
      // Array to hold all carousel images
      images: [],
      // Index of the active image on the images array
      activeImage: 0,
    };
  },
  computed: {
    // currentImage gets called whenever activeImage changes
    // and is the reason why we don't have to worry about the
    // big image getting updated
    currentImage() {
      // this.timeLeft = this.autoSlideInterval;
      this.images.length === 0
        ? this.images.push(...this.project.imagesGallery)
        : '';

      return this.images[this.activeImage].url;
    },
  },
  methods: {
    // Go forward on the images array
    // or go at the first image if you can't go forward :/
    nextImage() {
      let active = this.activeImage + 1;
      if (active >= this.images.length) {
        active = 0;
      }
      this.activateImage(active);
    },
    // Go backwards on the images array
    // or go at the last image
    prevImage() {
      let active = this.activeImage - 1;
      if (active < 0) {
        active = this.images.length - 1;
      }
      this.activateImage(active);
    },
    activateImage(imageIndex) {
      this.activeImage = imageIndex;
    },
  },
};
