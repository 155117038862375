<template>
  <header class="header">
    <div class="page-title">
      <div class="header__container">
        <div class="header__left">
          <h1 class="title is-1">
            <slot name="title"></slot>
            <span><slot name="collaboration"></slot></span>
          </h1>
        </div>
        <div class="header__right">
          <slot name="slotProps"></slot>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'TheHeader',
};
</script>


<style lang="scss" scoped>
.header {

  &__container {
    height: 26px;
  }

  &__right {
    @media (min-width: 961px) {
      text-align: right;
    }
  }
}

.page-title {
  margin: $gutter-desktop $gutter-desktop 0;

  @media (max-width: 960px) {
    margin: $gutter-mobile $gutter-mobile 0;
  }
  .header__container {
    @media (max-width: 960px) {
      display: block;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title.is-1 {
    @media (max-width: 600px) {
      font-size: $font-size-large;
      line-height: 24px;
    }
    
    font-size: $font-size-xlarge;
    font-weight: $font-weight-normal;

    span {
      color: $col-tertiary;
      padding-left: 6px;

      
    }
  }
}
</style>
