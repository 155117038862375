<template>
  <div class="loader-wrapper" id="loader-wrapper">
    <div class="loader" id="loader">
      <LogoSVG />
    </div>
  </div>
</template>

<script>
import LogoSVG from '../UI/LogoSVG.vue';

export default {
  name: 'BaseLoader',
  components: {
    LogoSVG,
  },
};
</script>

<style lang="scss" scoped>
/* local styles */

#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: $col-black;
}

#loader {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001; /* anything higher than z-index: 1000 of .loader-section */
}

svg {
  @media only screen and (max-width: 570px) {
    margin: 0 48px;
  }

  @media only screen and (min-width: 571px) {
    margin: 0 96px;
  }

  @media only screen and (min-width: 768px) {
    margin: 0 144px;
  }

  -webkit-animation: fadeInAndOut 4s linear forwards;
  animation: fadeInAndOut 4s linear forwards;
}

@-webkit-keyframes fadeInAndOut {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes fadeInAndOut {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
</style>
