// Vanilla JS example with all options
// https://alvarotrigo.com/fullPage/docs/
export default {
  data() {
    return {
      window: {
        width: 0,
      },
      options: {
        // YOUR_KEY_HERE
        licenseKey: process.env.VUE_APP_FULLPAGE,

        // Navigation
        // Determines whether anchors in the URL will have any effect at all in the library
        lockAnchors: false,
        navigation: true,

        // Scrolling
        scrollBar: false,

        // Design
        verticalCentered: false,

        // How to activate a fullPage.js extension
        // https://github.com/alvarotrigo/fullPage.js/wiki/How-to-activate-a-fullPage.js-extension

        // Enables drag and move
        // true: enables the feature.
        // false: disables the feature.
        // vertical: enables the feature only vertically.
        // horizontal: enables the feature only horizontally.
        // fingersonly: enables the feature for touch devices only.
        // mouseonly: enables the feature for desktop devices only (mouse and trackpad).
        dragAndMove: true,
        dragAndMoveKey: process.env.VUE_APP_DRAGANDMOVEKEY, // ACTIVATION KEY SHOULD BE HERE
        controlArrows: false,
        showActiveTooltip: true,
        slidesNavigation: true,
        slidesNavPosition: 'top',
        autoScrolling: true,

        // Custom selectors
        // https://webdesign.tutsplus.com/articles/vertical-and-horizontal-scrolling-with-fullpagejs--cms-24215
        sectionSelector: '.section', // sectionSelector: '.vertical-scrolling',
        slideSelector: '.slide', // slideSelector: '.horizontal-scrolling',
        normalScrollElements: '.block-content, .accordion, .accordion-body',

        lazyLoading: true,
        scrollOverflow: false,
        credits: {
          enabled: false,
        },

        // Events
        afterLoad: this.afterLoad,
        onLeave: this.onLeave,
        afterSlideLoad: this.afterSlideLoad,
        onSlideLeave: this.onSlideLeave,
      },
    };
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
    },
    afterLoad: function (anchor) {
      const isDevice = this.window.width <= 960;
      const navigation = anchor.item.querySelector('.fp-slidesNav');
      isDevice ? navigation.setAttribute('id', 'fp-slidesNav') : '';
    },
    onLeave: function (origin, destination) {
      const originMedia = origin.item.querySelector('mux-player');
      const media = destination.item.querySelector('mux-player');
      // https://github.com/muxinc/elements/blob/main/packages/mux-player/REFERENCE.md#methods
      media !== null ? media.play() : '';
      originMedia !== null ? originMedia.pause() : '';
    },
    afterSlideLoad: function (section, origin, destination) {
      const originMedia = origin.item.querySelector('mux-player');
      const media = destination.item.querySelector('mux-player');
      media !== null ? media.play() : '';
      originMedia !== null ? originMedia.pause() : '';
    },
    onSlideLeave: function (section, origin, destination) {
      const currentSlide = destination.index;
      const readEl = document.querySelector('.read');
      const distRead = destination.item.querySelector('.read');
      const navigation = document.querySelector('#fp-slidesNav');
      const anchors = document.querySelectorAll('#fp-slidesNav > ul > li > a');
      //console.log(currentSlide)

      const makeActive = evt => {
        anchors.forEach(el => el.classList.remove('active'));
        anchors[evt].classList.add('active');
      };

      let n = 0;
      while (n < anchors.length) {
        currentSlide === n ? makeActive(currentSlide) : '';
        n++;
      }

      distRead !== null && readEl.innerHTML === 'Read Less'
        ? navigation.classList.add('d-none')
        : navigation.classList.remove('d-none');
    },
  },
};
