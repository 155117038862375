import sanity from '../../client.js';
import imageUrlBuilder from '@sanity/image-url';
const imageBuilder = imageUrlBuilder(sanity);

const query = `{
  "navbar": *[_type == "navigation"][0] {
    title,
   body,
  },
  "projects": *[_type == "portfolio"] | order(launchDate desc) {
    title,
    collaboration,
    description,
    websiteUrl,
    contentType,
    'category': categories[]->title,
    'video': video.asset->{
      'aspectRatio': data.aspect_ratio,
      'playbackId': playbackId,
    },
    "imagesGallery": imagesGallery[].asset->{
      _id,
      url
    },
    "mainImage": mainImage.asset->{
      _id,
      url
    },
    "video": video.asset->
    }
}`;

export default {
  data() {
    return {
      loading: true,
      posts: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    imageUrlFor(source) {
      return imageBuilder.image(source);
    },
    fetchData() {
      this.error = this.posts = null;
      this.loading = false;

      sanity.fetch(query).then(
        posts => {
          this.loading = false;
          this.posts = posts;
        },
        error => {
          this.loading = false;
          this.error = error;
        }
      );
    },
  },
};
