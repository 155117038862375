<template>
  <div class="master-wrapper-page" :data-index="index">

    <TheHeader>
      <template v-slot:title>{{ project.title }}</template>
      <template v-slot:collaboration>{{ project.collaboration }}</template>
    </TheHeader>
    
    <div class="master-wrapper-content">
      
        <!--video
          :autoplay="autoplay(index)"
          :poster="projectImage(project.video.playbackId)"
          ref="videoPlayer"
          class="video-js vjs-16-9 vjs-default-skin"
          preload="auto"
          width="100%"
          loop
          playsinline
          muted
          crossorigin
        ></video-->
        <mux-player
          :autoplay="autoplay(index)"
          :playbackId="project.video.playbackId"
          :metadata-video-title="project.title"
          :style="aspectRatio(project.video.data.aspect_ratio)"
          metadata-viewer-user-id="website"
          stream-type="on-demand"
          meta
          playsinline
          muted
          loop
        ></mux-player>
      
    </div>

    <div class="categ">
      <base-category :categories="project.category"></base-category>
    </div>
  </div>
</template>

<script>
import TheHeader from '../components/layout/TheHeader.vue';
import BaseCategory from '../components/UI/BaseCategory.vue';
//import muxMixin from '../components/mixins/mux.js';
import "@mux/mux-player";

export default {
  name: 'FolioVideo',
  props: ['project','index'],
  components: {
    TheHeader,
    BaseCategory,
  },
  methods: {
    projectImage(playbackId) {
      // https://docs.mux.com/guides/video/get-images-from-a-video#get-an-image-from-a-video
      const projImg = "https://image.mux.com/" + playbackId + "/thumbnail.webp?width=1920&height=1080&fit_mode=preserve&time=0.01"
      return projImg;
    },
    autoplay(index) {
      if (index === 0) {
        return true;
      } else {
        return false;
      }
    },
    aspectRatio(aspectRatio) {
      const ratio = aspectRatio.split(':');
      //console.log(ratio[0] + '/' + ratio[1]);

      return {
        aspectRatio: ratio[0] + '/' + ratio[1]
      }
    },
    vidId(index) {
      return "video-" + index;
    },
  }
};
</script>


<style lang="scss" scoped>
@import '@/assets/scss/layout.scss';

.master-wrapper-content {
  padding: 0 15px;

  @media screen and (min-width: 1023px) {  
    padding: 0;
  }
}

mux-player {
  background-color: transparent;
  --controls-backdrop-color: transparent;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  overflow: hidden;
  //aspect-ratio: 16 / 9;
  --media-object-fit: cover;
  --controls: none;
  width: 100%;
  height: auto;
  margin: 0 auto;
  

  @media screen and (min-width: 961px) {  
    width: auto;
    height: 100%;
    max-height: 60vh;
    max-width: 90vw;
  }
  
  @media screen and (min-width: 1200px) { 
    max-height: 70vh;
  }

  &::part(gesture-layer) {
    display: none;
  }
}


.gallery {
  //border: 1px solid $col-tertiary;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  overflow: hidden;
  max-height: calc(100vh - 300px);
  text-align: center;
  

  @media screen and (min-width: 1023px) {  
    max-height: 70vh;
  }


}

</style>
