<template>
  <folio-video
    v-if="project.contentType === 'Video'"
    :project="project"
    :index="index"
  ></folio-video>
  <folio-images
    v-if="project.contentType === 'Images'"
    :project="project"
    :index="index"
  ></folio-images>
</template>

<script>
import FolioVideo from '../contentTypes/FolioVideo.vue';
import FolioImages from '../contentTypes/FolioImages.vue';

export default {
  name: 'PortfolioSlide',
  props: ['project', 'index'],
  components: {
    FolioVideo,
    FolioImages,
  },
};
</script>
