<template>
  <Transition name="blink">
    <div class="loading" v-if="isLoading">
      <img
        class="ld-favicon"
        alt="LD-Favicon"
        src="../../assets/images/LD-Favicon.svg"
      />
    </div>
  </Transition>
  <div class="error" v-if="isError">
    {{ error }}
  </div>
</template>

<script>
export default {
  name: 'BaseDialog',
  props: {
    isLoading: Boolean,
    isError: Boolean,
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/logo.scss';
</style>
