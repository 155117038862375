import { createApp } from 'vue';
import App from './App.vue';
import VueGtag from "vue-gtag";

// vue-fullpage.js + extensions
// https://github.com/alvarotrigo/vue-fullpage.js/issues/150
import './fullpage.dragAndMove.min'; // Optional. When using fullpage extensions
import 'fullpage.js/dist/fullpage.min.css';
import VueFullPage from 'vue-fullpage.js';

const app = createApp(App);
app.use(VueFullPage);
if (process.env.NODE_ENV === "production") {
  app.use(VueGtag, {
    config: { id: "G-1GFC8KMSWB" }
  })
}
app.mount('#app');
