<template>
  <Transition name="slide-fade">
    <svg
      width="1248"
      height="113"
      viewBox="0 0 1248 113"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0419 0H0.67749V113H69.9358V101.7H12.0419V0Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M182.936 0H193.872V90.4003H204.807V101.7H193.872V90.4003H182.936V0ZM248.549 0H259.485V90.4003H248.549V101.7H237.614V90.4003H248.549V0ZM237.614 113V101.7H204.807V113H237.614Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M153.254 113H164.71V22.6001H153.254V56.5002H95.9726V22.6001H107.429V11.3H141.798V0H107.429V11.3H95.9726V22.6001H84.5164V113H95.9726V67.8002H153.254V113ZM153.254 22.6001H141.798V11.3H153.254V22.6001Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1148.91 113H1159.84V22.6001H1148.91V56.5002H1094.23V22.6001H1083.29V113H1094.23V67.8002H1148.91V113ZM1148.91 22.6001H1137.97V11.3H1105.17V0H1137.97V11.3H1148.91V22.6001ZM1105.17 11.3V22.6001H1094.23V11.3H1105.17Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M281.356 67.8002V113H292.291V67.8002H336.034V56.5002H292.291V11.3H336.034V0H281.356V56.5002V67.8002ZM346.969 11.3H336.034V56.5002H346.969V11.3ZM314.163 67.8002H325.098V79.1003H332.732V90.4003H339.335V101.7H328.399V90.4003H321.797V79.1003H314.163V67.8002ZM346.969 101.7H336.034V113H346.969V101.7Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M398.001 0H365.195V11.3H398.001V113H408.937V11.3H441.743V0H398.001Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M475.462 0H521.026V11.3H475.462V0ZM475.462 11.3H464.07V22.6001H452.679V90.4003H464.07V101.7H475.462V90.4003H464.07V22.6001H475.462V11.3ZM521.026 11.3H532.417V22.6001H521.026V11.3ZM521.026 90.4003H532.417V101.7H521.026V90.4003ZM543.809 22.6001H532.417V90.4003H543.809V22.6001ZM521.026 101.7H475.462V113H521.026V101.7Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M566.443 0H562.034V113H573.161V22.6001H579.879V33.9001H586.596V45.2002H597.723V33.9001H591.005V22.6001H584.287V11.3H577.569V0H566.443ZM624.384 90.4003H631.102V101.7H619.976V90.4003H613.258V79.1003H606.54V67.8002H617.666V79.1003H624.384V90.4003ZM631.102 101.7H626.693V113H642.228V0H631.102V101.7ZM604.231 56.5002H610.948V67.8002H599.822V56.5002H593.104V45.2002H604.231V56.5002Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M733.878 101.7H779.703V113H722.422V101.7V0H779.703V11.3H733.878V101.7ZM791.16 22.6001H779.703V11.3H791.16V22.6001H802.616V90.4003H791.16V101.7H779.703V90.4003H791.16V22.6001Z"
        fill="white"
      />
      <path d="M835.423 0H824.487V113H835.423V0Z" fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M921.996 0H876.431V11.3H921.996V0ZM865.04 11.3H876.431V22.6001H865.04V90.4003H876.431V101.7H865.04V90.4003H853.649V22.6001H865.04V11.3ZM899.213 56.5002H944.778V67.8002V113H933.387V67.8002H899.213V56.5002ZM921.996 11.3H933.387V22.6001H921.996V11.3ZM944.778 22.6001H933.387V33.9001H944.778V22.6001ZM921.996 90.4003H933.387V101.7H921.996V90.4003ZM921.996 101.7H876.431V113H921.996V101.7Z"
        fill="white"
      />
      <path d="M977.584 0H966.649V113H977.584V0Z" fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1028.62 0H995.811V11.3H1028.62V113H1039.55V11.3H1072.36V0H1028.62Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1189.43 0H1178.07V101.7V113H1247.33V101.7H1189.43V0Z"
        fill="white"
      />
    </svg>
  </Transition>
</template>

<script>
export default {
  name: 'LogoSVG',
};
</script>
