<template>
  <main>
    <div class="master-wrapper-page" :data-index="index">

      <TheHeader>
        <template v-slot:title>{{ project.title }}</template>
        <template v-slot:collaboration>{{ project.collaboration }}</template>
        <template v-slot:slotProps>
          <button class="read" v-on:click="activate">{{ visible }}</button>
        </template>
      </TheHeader>

     

      <div :class="classObject">
        <div class="gallery">
          <div class="card-carousel">
            <div class="card-img">
              <img :src="currentImage" alt="" />

              <div class="thumbnails">
              <div
                v-for="(image, index) in images"
                :key="image._id"
                :class="[
                  'thumbnail-image',
                  activeImage == index ? 'active' : '',
                ]"
                @click="activateImage(index)"
              >
                <img :src="image.url" />
              </div>
            </div>
            </div>
            
            
          </div>
        </div>
        <transition name="fade">
          <section class="block-content" v-if="!show">
            <div class="block-content__inner">
              <SanityBlocks :blocks="project.description" />
            </div>
          </section>
        </transition>
      </div>

      <div class="categ" v-if="showParagraph">
        <base-category :categories="project.category"></base-category>
      </div>
    </div>
  </main>
</template>

<script>
import { SanityBlocks } from 'sanity-blocks-vue-component';
import TheHeader from '../components/layout/TheHeader.vue';
import BaseCategory from '../components/UI/BaseCategory.vue';

import carouselMixin from '../components/mixins/carousel.js';

export default {
  name: 'FolioImages',
  mixins: [carouselMixin],
  props: ['project', 'index'],
  components: {
    TheHeader,
    
    BaseCategory,
    SanityBlocks,
  },
  data() {
    return {
      show: true,
      windowWidth: window.innerWidth,
      imagesGallery: [],
    };
  },
  computed: {
    visible() {
      return this.show ? 'Read More' : 'Read Less';
    },
    showParagraph() {
      return this.show || this.windowWidth > 960;
    },
    classObject() {
      return this.show
        ? 'master-wrapper-content'
        : 'master-wrapper-content block--mod';
    },
  },
  methods: {
    activate() {
      const read = document.querySelector('.read');
      const navigation = document.getElementById('fp-slidesNav');

      if (navigation !== null) {
        read.innerHTML === 'Read Less'
          ? navigation.classList.remove('d-none')
          : navigation.classList.add('d-none');
      }
      return (this.show = !this.show);
    },
  },
  // Add a property windowWidth on the component's data and attach the resize
  // listener that modifies the value when the component is mounted.
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/layout.scss';
@import '@/assets/scss/carousel.scss';

.fade-enter-from { opacity: 0 }

.fade-enter-active,
.fade-leave-active {
  transition: opacity 500ms ease
}
.fade-leave-to   { opacity: 0 }

.read {
  @media (max-width: 600px) {
    font-size: $font-size-large;
  }
  cursor: pointer;
  user-select: none;
  color: $col-tertiary;
  font-size: $font-size-xlarge;
  outline: none;
  border: none;
  -webkit-appearance: none;
  background: none;
  padding: 0;

  &:hover {
    cursor: pointer;
  }
}

.block-content {
  position: absolute;
  top: 80px;
  left: 0;
  width: 100vw;
  height: 100%;
  padding: $gutter-mobile;
  background: $col-white;
  height: calc(100vh - 150px);
  overflow-y: auto;

    &:before {
      content: '';
      background: linear-gradient(180deg, #fff 0%, #fff 50%, rgba(195, 87, 26, 0) 100%);;
      height: 20px;
      position: fixed;
      top: 80px;
      left: 0;
      width: 100%;
      z-index: 5;
    }

  @media (min-width: 1023px) {
    top: 90px;
    left: $gutter-desktop;
    width: 100%;
    //height: 100%;
    height: calc(100vh - 100px);
    padding-left: 0;
    padding-top: 0;

    &:before {
      top: auto;
    }
  }

  &__inner::v-deep {
    font-size: 16px;
    line-height: 120%;
    max-width: calc(100vw - #{$gutter-mobile * 2});
    padding-bottom: 50px;
    padding-top: 0;
    margin: 0 0 1em;
      
    

    @media (min-width: 1023px) {
      max-width: calc(80vw - #{$gutter-mobile * 2});
      font-size: $font-size-xlarge;
      line-height: 26px;
      padding-top: 20px;
    }

    @media (min-width: 1400px) {
      max-width: 65vw;
    }
    @media (min-width: 1400px) {
      max-width: 50vw;
    }

    p {
      margin-bottom: 1.5rem;
    }
  }
}

.master-wrapper-content {
  //position: relative;

  @media (min-width: 1023px) {
    .card-img {
      max-height: 70vh;

      img {
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
</style>
